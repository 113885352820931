<template>
    <div>
        <overtime-work-list-for-manager></overtime-work-list-for-manager>
    </div>
    </template>
    
        
    <script>
    export default {}
    </script>
    